import React, { useState, useEffect } from "react"
import { InView } from "react-intersection-observer"
import classNames from "classnames"
import MarkdownViewer from "@components/MarkdownViewer"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"

const Timeline = ({ items = [] }) => {
  return (
    <div className="row">
      <div className="timeline">
        {items.map((item, i) => (
          <React.Fragment key={item.title}>
            <SingleTimeBlock
              isLeftSide={i % 2 === 0}
              title={item.title}
              content={item.content}
              startsVisible={item.startsVisible}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const SingleTimeBlock = ({ isLeftSide, title, content, startsVisible }) => {
  const [isVisible, setIsVisible] = useState(startsVisible)
  const [windowWidth, setWindoWidth] = useState(0)

  useEffect(() => {
    if (typeof window !== undefined) {
      setWindoWidth(window.innerWidth)
    }
  }, [])

  const mainClasses = classNames("timeline__container", {
    timeline__right: !isLeftSide,
    timeline__left: isLeftSide,
  })

  const activeClasses = classNames({
    timeline__active: isVisible,
  })

  const chevrons = isLeftSide ? faChevronLeft : faChevronRight

  let animationClass = classNames({
    slideInRight: isLeftSide && isVisible && windowWidth > 600,
    slideInLeft: (!isLeftSide && isVisible) || (isVisible && windowWidth < 600),
  })

  return (
    <>
      <div className={mainClasses}>
        <InView
          as="div"
          onChange={(inView) => (inView ? setIsVisible(true) : null)}
          threshold={1}
        >
          <div className="timeline__time">{title}</div>
          <div
            className={`timeline__arrow ${activeClasses}`}
            onClick={() => setIsVisible(!isVisible)}
          >
            <FontAwesomeIcon icon={chevrons} />
          </div>
          <div
            className={`timeline__content ${activeClasses} ${animationClass}`}
          >
            <MarkdownViewer markdown={content} />
          </div>
        </InView>
      </div>
    </>
  )
}

export default Timeline
